<template>
  <div>
    <div class="bg" v-if="showAll" @click="closeAll()">
      <!-- 全图背景，点击可关闭此组件 -->
    </div>
    <div class="main" v-if="showAll">
      <h2 id="title">创建任务</h2>
      <img
        :src="close"
        @click="closeAll()"
        alt=""
        class="close"
        style="cursor: pointer"
      />
      <!-- img的点击事件控制关闭整个组件，mouse事件切换图标 -->
      <div id="line">
        <!-- 分割线 -->
      </div>

      <el-form ref="form" :model="form" :rules="rules" label-width="100px" class="add-task-form">
            <el-form-item label="标题" prop="title">
                <el-input v-model.trim="form.title" clearable placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="sector_id">
                <el-select v-model="form.sector_id"   placeholder="部门" class="select" @change="handleSector">
                    <el-option v-for="(item, index) in sector" :key="'sector'+index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="no-margin">
              <el-col :span="12"> 
                <el-form-item label="任务类型">
                  <el-select v-model="form.type" multiple collapse-tags filterable allow-create default-first-option  placeholder="任务类型" class="select" @change="handleChangeType">
                    <el-option v-for="(item, index) in taskType" :key="'type'+index" :label="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12"> 
                <el-form-item label="任务紧急度">
                  <el-select v-model="form.level" placeholder="任务紧急度" class="select" >
                    <el-option v-for="(item, index) in tasklevel" :key="'level'+index"  :value="item.value">
                      <div class="level-option">
                        <img :src="item.icon" alt="">
                        <span>{{item.value}}</span>
                      </div>
                        
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item class="no-margin">
              <el-col :span="12"> 
                <el-form-item label="负责人">
                  <img
                    class="cursor"
                    v-if="form.charge.length == 0"
                    :src="add_member"
                    alt=""
                    @click="showMember = true"
                  />
                    <div class="member-lis" v-else >
                      <span v-for="(v,i) in form.charge" :key="'charge'+i" class="cursor" @click="delMember('mem',i)">{{ lastName(v.name) }}</span>
                    </div>
                    <div class="mem" @click="$event.stopPropagation()">
                        <member
                          class="member-container"
                          v-if="showMember"
                          :userType="userType"
                          :sector_id="form.sector_id"
                          @pickit="pickitMem"
                        />
                    <div
                        class="cover-mark"
                        v-show="showMember"
                        @click.stop="$event.stopPropagation(), (showMember = false)"
                    ></div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12"> 
                <el-form-item label="参与人">
                  <div class="join-box">
                    <div class="member-lis" >
                        <span v-for="(v,i) in form.join" :key="'charge'+i" class="cursor" @click="delMember('join',i)">{{ lastName(v.name) }}</span>
                        <img
                          class="cursor"
                          :src="add_member"
                          alt=""
                          @click="showJoin = true"
                        />
                    </div>
                    
                  </div>
                  <div class="mem" @click="$event.stopPropagation(), (showJoin = true)">
                    <member
                      class="member-container"
                      v-if="showJoin"
                      @click="$event.stopPropagation()"
                      :multiple="true"
                      :userType="userType"
                      :sector_id="form.sector_id"
                      :currArr="form.join.map(v=> v.id)"
                      @pickit="pickitJoinMem"
                    />
                    <div
                      class="cover-mark"
                      v-show="showJoin"
                      @click="$event.stopPropagation(), (showJoin = false)"
                    ></div>
                  </div>
                </el-form-item>
              </el-col>
            </el-form-item>
            <!-- 审核箱的创建才有审核人 -->
            <el-form-item class="no-margin" v-if="name == 'audit'"> 
              <el-col :span="12"> 
                <el-form-item label="审核人" class="is-required">
                  <img
                    class="cursor"
                    v-if="form.check.length == 0"
                    :src="add_member"
                    alt=""
                    @click="showAuditMember = true"
                  />
                    <div class="member-lis" v-else >
                      <span v-for="(v,i) in form.check" :key="'check'+i"  class="cursor" @click="delMember('audit-mem',i)">{{ lastName(v.name) }}</span>
                    </div>
                    <div class="audit-mem" @click="$event.stopPropagation()">
                        <member
                          class="member-container"
                          v-if="showAuditMember"
                          :userType="userType"
                          :sector_id="form.sector_id"
                          @pickit="pickitAuditMem"
                        />
                    <div
                        class="cover-mark"
                        v-show="showAuditMember"
                        @click="$event.stopPropagation(), (showAuditMember = false)"
                    ></div>
                  </div>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="taskNew('form')">保存</el-button>
              <el-button @click="closeAll">取消</el-button>
            </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import member from './member';
import { postTaskType, postTaskNew } from '@/service/help';
import { Message } from 'element-ui';
import { tasklevel } from '@/common/js';
import { differenceArr } from '@/utils/utils'
export default {
  name: 'addTask',
  components: {
    member,
  },
  data() {
    return {
      close: require('@/assets/image/progress/addTask/close2.png'),
      add_member: require('@/assets/image/progress/addTask/add_member1.png'),
      taskType:[], // 任务类型
      tasklevel:tasklevel,
      form:{
        title:'',
        type:[],
        level:'',
        charge:[],
        join:[],
        sector_id:'', // 部门id
        sector_name:'', // 部门名称
        is_check:1, // 是否是审核创建
        check:[] // 审核用户id
      },
      rules:{
        title:[
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        sector_id:[
          { required: true, message: '请选择部门', trigger: 'change' },
        ]
      },
      showMember:false,
      showJoin:false,
      showAuditMember:false, // 审核人的member
      userType:'all' // 将要获取的人员列表类型 all 全部人 user 部门下的人
      
    };
  },
  computed:{
    sector:function(){
      let userInfo = this.$store.state.userInfo
      let arr = []
      if(userInfo.snames.length>0){
        userInfo.snames.map((v,i)=>{
          arr.push({id:userInfo.section_ids[i],name:v})
        })
      }
      return [{id:'0',name:'全部部门'}].concat(arr)
    }
  },  
  props:{
    name:String, // task是普通任务创建  audit是审核任务创建
    showAll:Boolean
  },
  watch:{
    showAll:{
      handler(newVal,oldVal){
        console.log(oldVal,newVal,'watch')
        if(newVal){
           postTaskType().then(res => {
              this.taskType = res.data.data;
            });
        }
       
      }
    }
  },
  created() {
    postTaskType().then(res => {
      this.taskType = res.data.data;
    });
   
  },
  methods: {
    handleChangeType(val){ // 任务类型handle
      console.log(val,'type=====')
      console.log(differenceArr(this.taskType,val))
      this.taskType.push(...differenceArr(this.taskType,val))
    },
    handleSector(val){ // 部门handle
      console.log(val,'val')
      if(val == '0'){
        this.userType = 'all'
      }else{
        this.userType = 'user'
      }
    },
    pickitJoinMem(data){
      let arr = this.form.join.map(v=> v.id)
      if (arr.includes(data.id)) {
        let old_ind = this.form.join.findIndex(v=>{
           return v.id == data.id
        })
        this.form.join.splice(old_ind,1)
      } else {
        this.form.join.push(data);
      }
    },
    pickitMem(val){
      console.log(val)
      this.form.charge = [val]
      this.showMember = false
    },
    pickitAuditMem(val){
      console.log(val,'===')
      this.form.check = [val]
      this.showAuditMember = false
    },
    delMember(type,index){
      if(type == 'mem'){
        this.form.charge.splice(index,1)
        this.showMember = true
      }else if(type == 'audit-mem'){
        this.form.check.splice(index,1)
        this.showAuditMember = true
      }else{
        this.form.join.splice(index,1)
      }
    },
    closeAll() {
        this.form = {
          title:'',
          type:[],
          level:'',
          charge:[],
          join:[],
          sector_id:'', // 部门id
          sector_name:'', // 部门名称
          is_check:1, // 是否是审核创建
          check:[] // 审核用户id
        },
        this.$emit('changeShow', false);
      
    },
    taskNew(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            let level = this.tasklevel.filter(v=>{ return v.value == this.form.level })
            let postData = {
                title: this.form.title,
                type: this.form.type,
                level: level.length == 0 ? '' : level[0].key.toString(),
                charge_id: this.form.charge.length > 0 ? this.form.charge[0].id : '',
                join_id: this.form.join.map(v=> v.id),
                sector_id:this.form.sector_id
            }
            if(this.name == 'audit'){ // 是审核创建时
                if(this.form.check.length == 0){
                  Message({
                    type: 'error',
                    message: '审核人不能为空！',
                  });
                  return
                }
                postData.is_check = 1
                postData.check_uid = this.form.check.length > 0 ? this.form.check[0].id : ''
            }
            
            console.log(level)
            postTaskNew(postData).then(res => {
                if(!res.data.success){
                  Message({
                    type: 'error',
                    message: '新建失败',
                  });
                  return
                }
                Message({
                  type: 'success',
                  message: '新建成功',
                });
                this.closeAll();
              });
          } else {
            console.log('error submit!!');
            return false;
          }
      });
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
  },
 
};
</script>

<style lang="less">
.bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}
.main {
  width: 800px;
  //   height: 355px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  position: fixed;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-60%);
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  color: #334681;
  #title {
    width: 72px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    line-height: 25px;
    opacity: 1;
    margin: 13px 0 0 30px;
    float: left;
  }
  .close {
    width: 13px;
    height: 13px;
    float: right;
    margin: 19px 20px 0 0;
  }
  #line {
    width: 800px;
    height: 1px;
    background: #e8ecef;
    opacity: 1;
    margin-top: 50px;
  }

  .add-task-form{
    width: 85%;
    margin: 20px  auto 0 auto;

    .no-margin{
      .el-form-item__content{
        position: relative;
        margin-left:0 !important;
      }
      
    }
    .mem{
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      .member-container{
        width: 100%;
        height: 1px;
        position: relative;
        top: 0;
        .main{
          position: absolute;
          top: 0 !important;
        }
      }
    }
   .member-lis{
     span{
       display: inline-block;
       width: 36px;
       height: 36px;
       text-align: center;
       line-height: 36px;
       color: #fff;
       background: #46d8ff;
       border-radius: 50%;
     }
   }
   .join-box{
      width: calc(~'100% - 100px');
      float: right;
      &::after{
        content: '';
        display: block;
        clear: both;
      }
      img{
        float: left;
      }
   }
   .join-box .member-lis{
      float: left;
      &::after{
        content: '';
        display: block;
        clear: both;
      }
      span{
        float: left;
        margin:  0 5px 5px 0;
      }
    }
  }
  
  
}

.el-select-dropdown .el-select-dropdown__item{
  .level-option{
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span{
      padding-left: 10px;
    }
  }
}

.cursor{
  cursor: pointer;
}
</style>
