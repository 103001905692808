<template>
  <div class="progress" @click="resetShow">
    <div class="top-box">
      <span class="title">任务进度管理</span>
      <template>
        <el-divider direction="vertical"></el-divider>
        <span class="section">{{ userInfo.cname }}{{ userInfo.snames.length == 0 ? '' : '-' + userInfo.snames.join('/') }}</span>
      </template>
    </div>
    <nav>
      <ul class="see">
        <li
          v-for="item in funList"
          :key="item.key"
          @click="handleNav(item)"
          :class="{ aliveNav: aliveKey === item.key }"
        >
          <div :class="['ico', item.ico]" />
          <span>{{ item.str }}</span>
        </li>
      </ul>
      <el-button class="export" @click.native="exportEx">导出excel</el-button>
      <ul class="iFun">
        <li @click="isAuditShow = true">
          <span class="ico"><img src="@/assets/image/icon-progress/icon_audit_box.png" width="18" alt=""/></span>
          <span>任务审核箱</span>
          <span class="audit-num" v-if="checkNum>0">{{checkNum}}</span>
        </li>
        <li @click="isAddShow = true">
          <span class="ico">
            <img src="../../../assets/image/icon-progress/icon_xj@2x.png" alt width="24" />
          </span>
          <span>新建</span>
        </li>
      </ul>
    </nav>
    <div class="search">
       <el-button v-for="(v,i) in xIco" :key="'search'+i" :type="v.checked ? 'primary' : ''" @click="handleTaskStatus(v,i)">{{v.str}}</el-button>
        <el-date-picker
          class="search-ftime"
          v-model="searchObj.ftime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          clearable
          @change="hanleFtime">
        </el-date-picker>
        <el-input v-model.trim="searchObj.keyText" class="search-ftime" clearable placeholder="请输入关键字，按Enter检索"  style="width:240px;height:36px;margin-left:10px" @keydown.enter.native="search" @clear="search"></el-input>
    </div>
    <div class="result" v-show="aliveKey == 'allTask' || aliveKey == 'myTask'">
      <div class="task-col">
        <div :class="v.class" v-for="(v) in taskColLeft">{{v.name}}</div>
        <div class="task-col-right">
          <div :class="v.class" v-for="(v) in taskColRight">{{v.name}}</div>
        </div>
      </div>
      <el-empty v-if="allTask.length == 0" description="暂无数据"></el-empty>
      <ul v-else>
        <li
          v-for="(item, index) in allTask"
          :key="`${aliveKey == 'allTask' ? 'allTask' : 'myTask'}` + item.id"
          @click="showById(item.id, item.title)"
          @contextmenu.prevent="delTask(item.id)"
        >
        <!-- 排序编号 -->
          <!-- <span class="task-index" @click.stop="showTaskIndex(index+1)">{{index+1}}</span> -->
          <span class="task-sn">{{ item.sn }}</span>
          <span
            :style="{ backgroundColor: xIco[item.status].bgColor, color: xIco[item.status].color }"
            class="status-ico"
          >
            <img :src="xIco[item.status].ico" width="14" alt />
            <span>{{ xIco[item.status].str }}</span>
          </span>
          <!-- 任务类型 -->
          <span :class="['user-task',userTaskType(item.sector_id).class]">{{userTaskType(item.sector_id).label}}</span>
          <span class="title-show" :title="item.title">{{ item.title }}</span>
          <!-- <div class="head-draw portrait" @click.stop="memberDisplay($event, index)" v-if="item.name !== ''">
            {{ lastName(item.name) }}
          </div>-->
          <div class="task-detail">
            <span class="detail-item">{{item.type}}</span>
            <span class="detail-item"><img :src="level[item.level].icon" width="14" alt v-if="level[item.level]"/> <span v-if="level[item.level]">{{level[item.level].value}}</span></span>
            <span class="detail-item">{{getNewDateFormat(item.utime)}}</span>
            <span class="detail-item">{{getNewDateFormat(item.ctime)}}</span>
            <span class="detail-item">{{getDate(item.ftime,'date')}}</span>
            <img class="portrait avator" :src="item.avatar" :alt="lastName(item.name)" v-if="item.name !== '' && item.avatar != defaultImg" />
            <div class="tx avator" v-if="item.name !== '' && item.avatar == defaultImg">{{lastName(item.name)}}</div>
            <div class="tj portrait avator" @click.stop="memberDisplay($event, index)" v-if="item.name === ''" />
            <div class="ico-trash" @click.stop="delTask(item.id)" />
            <div class="mem" @click="$event.stopPropagation()">
              <member :x="memberX" :y="memberY" :clickBoxHeight="clickBoxHeight" position_top="no-unset" :userType="item.sector_id == '0' ? 'all' : 'user'" :sector_id="item.sector_id" v-if="item.showMember" @fromMember="fromMember" />
            </div>
          </div>
          
          
        </li>
      </ul>
      
    </div>

    <div class="result-by-one" v-show="aliveKey === 'byOne'">
      <div class="task-col task-one-col">
        <div :class="v.class" v-for="(v) in taskColLeft">{{v.name}}</div>
        <div class="task-col-one-right">
          <div :class="v.class" v-for="(v) in taskColOneRight">{{v.name}}</div>
        </div>
      </div>
      <el-empty v-if="byOne.length == 0" :description="userInfo.snames.length == 0 ? '该账号未绑定部门' : '暂无数据'"></el-empty>
      <template v-else>
      <ul v-for="(one, index) in byOne" :key="index">
        <div class="name">
          <!-- <div class="head-draw">{{ lastName(section.name) }}</div> -->
          <img style="vertical-align: middle" src="@/assets/image/icon-progress/icon_abm_bl@2x.png" width="20" />
          <!-- <img class="head-draw" :src="item.avatar" :alt="lastName(one.name)"> -->
          <span style="vertical-align: middle; margin-left: 8px">{{ one.name }}({{ one.userinfo.length }})</span>
        </div>
        <div v-for="(user,uind) in one.userinfo" :key="'user'+uind" class="user-one">
          <div class="name"  @click="user.show = !user.show">
            <div class="head-draw">{{ lastName(user.name) }}</div>
            <!-- <img class="head-draw" :src="item.avatar" :alt="lastName(one.name)"> -->
            <span>{{ user.name }}</span>
            <img
              class="direction"
              src="../../../assets/image/icon-progress/icon_up@2x.png"
              width="16px"
              v-if="user.show"
            />
            <img class="direction" src="../../../assets/image/icon-progress/icon_down@2x.png" width="16px" v-else />
          </div>
          <li v-for="(task, index) in user.tasks" :key="index" @click="showById(task.id, task.title)" v-show="user.show">
            <span class="sn">{{ task.sn }}</span>
            <span
              v-if="xIco[task.status]"
              :style="{ backgroundColor: xIco[task.status].bgColor, color: xIco[task.status].color }"
              class="status-ico"
            >
              <img :src="xIco[task.status].ico" width="14" alt />
              <span>{{ xIco[task.status].str }}</span>
            </span>
            <span :class="['user-task',userTaskType(task.sector_id).class]">{{userTaskType(task.sector_id).label}}</span>
            <span class="sub-title">{{ task.title }}</span>
            <div class="task-detail">
              <span class="detail-item">{{task.type}}</span>
              <span class="detail-item"><img :src="level[task.level].icon" width="14" alt v-if="level[task.level]"/> <span v-if="level[task.level]">{{level[task.level].value}}</span></span>
              <span class="detail-item">{{getNewDateFormat(task.utime)}}</span>
              <span class="detail-item">{{getNewDateFormat(task.ctime)}}</span>
              <span class="detail-item">{{getDate(task.ftime,'date')}}</span>
            </div>
          </li>
        </div>
        
      </ul>
    </template>
    </div>

    <div class="result-by-one" v-show="aliveKey === 'bySection'">
      <div class="task-col task-one-col">
        <div :class="v.class" v-for="(v) in taskColLeft">{{v.name}}</div>
        <div class="task-col-one-right">
          <div :class="v.class" v-for="(v) in taskColOneRight">{{v.name}}</div>
        </div>
      </div>
      <el-empty v-if="bySection.length == 0" :description="userInfo.snames.length == 0 ? '该账号未绑定部门' : '暂无数据'"></el-empty>
      <template v-else>
      <ul v-for="(section, index) in bySection" :key="index">
        <div class="name">
          <!-- <div class="head-draw">{{ lastName(section.name) }}</div> -->
          <img style="vertical-align: middle" src="@/assets/image/icon-progress/icon_abm_bl@2x.png" width="20" />
          <!-- <img class="head-draw" :src="item.avatar" :alt="lastName(one.name)"> -->
          <span style="vertical-align: middle; margin-left: 8px">{{ section.name }}({{ section.tkdata.length }})</span>
        </div>
        <li v-for="(task, index) in section.tkdata" :key="index" @click="showById(task.id, task.title)">
          <span class="sn">{{ task.sn }}</span>
          <span
            v-if="xIco[task.status]"
            :style="{ backgroundColor: xIco[task.status].bgColor, color: xIco[task.status].color }"
            class="status-ico"
          >
            <img :src="xIco[task.status].ico" width="14" alt />
            <span>{{ xIco[task.status].str }}</span>
          </span>
          <span :class="['user-task',userTaskType('1').class]">{{userTaskType('1').label}}</span>
          <span class="sub-title">{{ task.title }}</span>
          <div class="task-detail">
              <span class="detail-item">{{task.type}}</span>
              <span class="detail-item"><img :src="level[task.level].icon" width="14" alt v-if="level[task.level]"/> <span v-if="level[task.level]">{{level[task.level].value}}</span></span>
              <span class="detail-item">{{getNewDateFormat(task.utime)}}</span>
              <span class="detail-item">{{getNewDateFormat(task.ctime)}}</span>
              <span class="detail-item">{{getDate(task.ftime,'date')}}</span>
            </div>
        </li>
      </ul>
      </template>
    </div>
    <detail v-if="showDetail" @isShowDetail="isShowDetail" @traClose="traDaily($event)" :id="taskId" >
      <template slot="task-back">
        <div class="back-master-task" v-if="traId.length>1"  @click="isShowDetail('taskBack')">
              <i class="el-icon-arrow-left"></i>
              <span class="back-text">返回</span>
        </div>
      </template>
    </detail>

    <add-task :show-all="isAddShow" name="task" @changeShow="addShow()" />

    <!-- 审核箱子 -->
    <el-dialog width="70%" custom-class="audit-box-dialog" append-to-body  :visible.sync="isAuditShow" :modal-append-to-body='false'>
        <auditBox v-if="isAuditShow"></auditBox>
    </el-dialog>

    <!-- 填写排序弹窗 -->
    <el-dialog width="300px" custom-class="sort-box-dialog" title="修改排序编号" append-to-body  :visible.sync="isSortShow" :modal-append-to-body='false'>
        <TaskIndex :num="currNumIndex" @handleSaveIndex="handleSaveIndex"></TaskIndex>
    </el-dialog>
  </div>
</template>

<script>
import detail from '@/components/task/progress/detail/detail';
import addTask from '@/components/task/progress/addTask';
import auditBox from '@/components/task/audit/auditBox.vue'
import TaskIndex from '@/components/task/progress/taskIndex.vue'
import {
  postTaskLisMyself,
  postTaskDel,
  postTaskLisWho,
  postTaskChargeMod,
  postTaskLisAll,
  taskLisSector,
  uncheckAmount
} from '@/service/help';
import Member from '@/components/task/progress/member';
import { lastName, _debounce, workbook2blob, openDownload, XLSX, dateFormat } from '@/utils/utils';
// import { lastName } from '@/utils/utils';
import { memberMixin, xIco, tasklevel as level, taskType } from '@/common/js';
import { NormalHeaderCol, AllHeaderCol,OtherHeaderCol } from '@/contants/tabCol'
export default {
  name: 'Progress',
  mixins: [memberMixin],
  data() {
    return {
      // funList: taskType,
      defaultImg:'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png',
      aliveKey: 'allTask',
      allTask: [],
      byOne: [],
      bySection: [],
      searchSelectMem:[],
      isAddShow: false, //新建
      xIco:xIco,
      level,
      searchObj:{
        status:[],
        ftime:[],
        keyText:''
      },
      // dateFormat('MM-DD')
      elsData: {
        isfocus: false,
        isreverse: false,
        iselblur: true,
      },
      isSelectMem: false,
      showDetail: false,
      keyword: '',
      taskId: Number, //detail的参数 id name task-name
      memberX: 0, //成员列表组件定位
      memberY: 0,
      clickBoxHeight:0,
      traId: [], //记录弹出数据
      taskColLeft:NormalHeaderCol,
      taskColRight:AllHeaderCol,
      taskColOneRight:OtherHeaderCol,
      // 审核箱
      isAuditShow:false,
      checkNum:0,
      // 任务排序
      isSortShow:false,
      currNumIndex:0 // 当前任务的排序号
    };
  },
  components: {
    Member,
    detail,
    addTask,
    auditBox,
    TaskIndex
  },
  mounted() {
    this.search();
    this.getCheckTaskNum()
  },
  methods: {
    // 排序弹窗回调
    handleSaveIndex(num){
      this.isSortShow = false
      // 接口回调
    },
    // 显示排序弹窗
    showTaskIndex(num){
      this.currNumIndex = num
      this.isSortShow = true
    },
    // 去掉时间的年份和秒
    getNewDateFormat(str){
      return str.substring(5,16)
    },
    // 获取任务待审核数量
    getCheckTaskNum(){
      uncheckAmount().then(res=>{
        if(res.data.success){
          this.checkNum = res.data.data.uncheck
        }
      })
    },
    // 搜索事件
    handleTaskStatus(item,index){
        this.searchObj.status = []
        this.xIco[index].checked = !this.xIco[index].checked
        this.xIco.map((v,i)=>{
          if(v.checked){
            this.searchObj.status.push(i)
          }
          
        })
        this.search();
    },
    hanleFtime(){
      this.search();
    },
    getDate(date,type){
      if(type == 'date'){
        return date.split(' ')[0]
      }else{
        return date.split(' ')[1]
      }
    },
    exportEx() {
      //导出
      let memberMap = {};
      this.member.forEach(v => {
        memberMap[v.id] = v.name;
      });
      let data,
        title = '任务',
        sheet;
      console.log(memberMap,'memberMap')
      if(this.aliveKey == 'byOne'){
        data = [];
        let targetArr =  this.byOne
        targetArr.forEach(v=>{
          let sname = v.sname // 部门
          v.userinfo.forEach(v2=>{
            let phone = v2.username
            v2.tasks.forEach(v3=>{
              let memberstr = '';
              if(v3.join_id.length>1){
                v3.join_id = JSON.parse(v3.join_id);
                v3.join_id.forEach(vn => {
                  if (memberMap[vn]) {
                    memberstr += `${memberMap[vn]};`;
                  }
                });
              }
              
              data.push({
                创建日期: v3.ctime,
                任务名称: v3.title,
                任务状态: this.xIco[v3.status].str,
                部门:sname,
                负责人: memberMap[v3.charge_id],
                负责人手机号码:phone,
                参与人: memberstr,
                完成日期: v3.ftime,
              });
            })
          })
        })
      }else if (this.aliveKey == 'bySection') {
        console.log(this.aliveKey);
        data = [];
        let targetArr = this.bySection;
        let dataProp = 'tkdata';
        targetArr.forEach(v => {
          let name = v.name;
          v[dataProp].forEach(v1 => {
            let memberstr = '';
            if(v1.join_id.length>1){
              let join_id = JSON.parse(v1.join_id);
              join_id.forEach(vn => {
                if (memberMap[vn]) {
                  memberstr += `${memberMap[vn]};`;
                }
              });
            }
            console.log(this.xIco[v1.status],'==========00000')
            data.push({
              创建日期: v1.ctime,
              任务名称: v1.title,
              任务状态: this.xIco[v1.status] ? this.xIco[v1.status].str : '',
              部门:name,
              负责人: memberMap[v1.charge_id],
              参与人: memberstr,
              完成日期: v1.ftime,
            });
          });
        });
      } else if (this.aliveKey == 'myTask') {
        console.log(this.allTask,'this.allTask')
        data = [];
        data = this.allTask.map(v => {
          let memberstr = '';
          if(v.join_id.length>1){
            let join_id = JSON.parse(v.join_id);
            join_id.forEach(vn => {
              if (memberMap[vn]) {
                memberstr += `${memberMap[vn]};`;
              }
            });
          }
          
          return {
            创建日期: v.ctime,
            任务名称: v.title,
            任务状态: this.xIco[v.status].str,
            负责人: v.name,
            参与人: memberstr,
            完成日期: v.ftime,
          };
        });
        console.log(data,'=====data')
      }else if(this.aliveKey == 'allTask' ){
        data = []
        postTaskLisAll({
          st:this.searchObj.ftime != null ? this.searchObj.ftime[0] : '',
          et:this.searchObj.ftime != null ? this.searchObj.ftime[1] : '',
          status: this.searchObj.status,
          keyword:this.searchObj.keyText,
          need_sub:true
        }).then(res => {
          console.log(res)
          let task = res.data.data;
          data = task.map(v => {
            let memberstr = '';
            if(v.join_id.length>1){
              let join_id = JSON.parse(v.join_id);
              join_id.forEach(vn => {
                if (memberMap[vn]) {
                  memberstr += `${memberMap[vn]};`;
                }
              });
            }
            
            return {
              创建日期: v.ctime,
              序号: v.sn,
              类型: v.is_subtask == 1 ? '子任务' : '任务',
              任务名称: v.title,
              任务状态: this.xIco[v.status].str,
              负责人: v.name,
              参与人: memberstr,
              完成日期: v.ftime,
            };
          });
          sheet = XLSX.utils.json_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet, title);
          const workbookBlob = workbook2blob(wb);
          openDownload(workbookBlob, `${title}.xls`);
          
        })
        return false
      }
      
      sheet = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, title);
      const workbookBlob = workbook2blob(wb);
      openDownload(workbookBlob, `${title}.xls`);
      console.log('sheet',data,sheet);
    },
    getData(arg) {
      
      // console.log(join_id,arr[2])
      postTaskLisAll({
        st:this.searchObj.ftime != null ? this.searchObj.ftime[0] : '',
        et:this.searchObj.ftime != null ? this.searchObj.ftime[1] : '',
        status: this.searchObj.status,
        keyword:this.searchObj.keyText,
      }).then(res => {
        let data = res.data.data;
        if (data !== undefined) {
          this.allTask = data;
          this.allTask.forEach(item => {
            this.$set(item, 'showMember', false);
          });
        } else {
          this.allTask = [];
        }
      });
    },
    handleNav(item) {
      let key = item.key;
      this.aliveKey = key;
    },
    isShowDetail(e) {
      //正常关闭
      if (e === 'taskBack') {
        this.traId.pop(); //为了可以套娃打开
      } else if (e === 'task') {
        this.traId = []; //直接关闭
      }
      this.showDetail = false;
      if (this.traId.length > 0) {
        let id = this.traId.pop().toString();
        this.$nextTick(() => {
          this.showById(id);
        });
      } else {
        this.search();
      }
    },
    traDaily(id) {
      this.showDetail = false;
      this.$nextTick(() => {
        this.showById(id);
      });
    },
    isShow(data) {
      this.iFun[0].isShow = data;
    },
    showById(id) {
      this.traId.push(id); //打开详情页是记录id
      this.taskId = id;
      this.showDetail = true;
    },
    lastName(name) {
      return lastName(name);
    },
    addShow() {
      this.isAddShow = false;
      this.search();
    },
    delTask(id) {
      this.$confirm('确认要删除该这条任务吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          postTaskDel({ id: id }).then(() => {
            this.search();
            this.reset = !this.reset;
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    search() {
     
      if (this.aliveKey === 'allTask') {
        this.getData(this.keyword);
      } else if (this.aliveKey === 'byOne') {
        postTaskLisWho({
          st:this.searchObj.ftime != null ? this.searchObj.ftime[0] : '',
          et:this.searchObj.ftime != null ? this.searchObj.ftime[1] : '',
          status: this.searchObj.status,
          keyword:this.searchObj.keyText
        }).then(res => {
          this.byOne = []
          if(res.data.success){
            this.byOne = res.data.data;
            this.byOne.forEach(item => {
              item.userinfo.forEach(user => {
                user['show'] = true
              });
            });
          }
          
        });
      } else if (this.aliveKey === 'myTask') {
        postTaskLisMyself({
          st:this.searchObj.ftime != null ? this.searchObj.ftime[0] : '',
          et:this.searchObj.ftime != null ? this.searchObj.ftime[1] : '',
          status: this.searchObj.status,
          keyword:this.searchObj.keyText
        }).then(res => {
          this.allTask = []
          if(res.data.success){
            this.allTask = res.data.data;
          }
          
        });
      } else if (this.aliveKey === 'bySection') {
        taskLisSector({
          st:this.searchObj.ftime != null ? this.searchObj.ftime[0] : '',
          et:this.searchObj.ftime != null ? this.searchObj.ftime[1] : '',
          status: this.searchObj.status,
          keyword:this.searchObj.keyText
        }).then(res => {
           if(!res.data.success){
            this.bySection = []
            return
           }

          console.log(res,'======res')
          this.bySection = res.data.data
          console.log(this.bySection,'this.bySection')
        });
      }
    },
    memberDisplay(e, i) {
      const that = this
      this.resetShow();
      this.taskId = this.allTask[i].id;
      let dom = e.target;
      console.log(dom.getBoundingClientRect(),'dom.getBoundingClientRect()')
      this.memberX = dom.getBoundingClientRect().x;
      this.memberY = dom.getBoundingClientRect().y;
      this.clickBoxHeight = dom.getBoundingClientRect().height
      this.$nextTick(() => {
        that.allTask[i].showMember = !that.allTask[i].showMember;
      }); 
      this.$forceUpdate()
    },
    fromMember(name, id) {
      console.log(name);
      postTaskChargeMod({
        id: this.taskId,
        charge_id: id.toString(),
      }).then(() => {
        this.search();
      });
    },
    resetShow() {
      const that = this
      this.$nextTick(() => {
        if(that.allTask.length>0){
          that.allTask.forEach(item => {
            item.showMember = false;
          });
        }
        
        that.elsData.isfocus = false;
        that.elsData.isreverse = false;
        that.isSelectMem = false;
        that.$forceUpdate()
      });
      
    },
    elblur() {
      // setTimeout(() => {
      //   if(this.elsData.iselblur){
      //     console.log('关')
      //
      //   }else{
      //     console.log('重置')
      //     this.$refs.elselectinput.focus();
      //     this.elsData.iselblur = true
      //
      //   }
      // },500)
    },
    elSelectFocusHandler() {
      //el-选择器修改下模拟focus处理
      if (!this.isSelectMem) {
        this.elsData.isfocus = true;
        this.elsData.isreverse = true;
        this.$refs.elselectinput.focus();
      } else {
        this.elsData.isfocus = false;
        this.elsData.isreverse = false;
      }
      this.isSelectMem = !this.isSelectMem;
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    funList() {
      let arr = taskType;
      // if (!['0','1'].includes(this.userInfo.level)) {
      //   arr = arr.slice(0, 1).concat(arr.slice(2, 4));
      // }
      return arr;
    },
    userTaskType(){
      return  function(id){
        if(id == '0'){
          return {label:'通用',class:'normal-task'}
        }else{
          return {label:'部门',class:'depart-task'}
        }

      }
    },
  },
  watch: {
    aliveKey: {
      handler() {
        this.search();
      },
      deep: true,
    },
    keyword: {
      handler() {
        _debounce(this.search, 1000)();
      },
    },
    isAuditShow:{
      handler(newVal,oldVal){
        if(!newVal){
          this.search();
          this.getCheckTaskNum()
        }
        console.log(newVal,oldVal)
      }
    }
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/progress.less';

// .mem {
//   position: fixed;
//   z-index: 10;
//   right: 250px;
//   margin-top: 60px;
// }

.select-mem {
  position: fixed;
  z-index: 20;
  left: 718px;
  margin-top: 60px;
}
</style>
