<template>
    <div class="audit-box" @click="resetShow">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane  :name="v.name" v-for="(v,i) in tab" :key="'audit'+i">
                <span slot="label">
                    <div class="audit-tab-title">
                        {{v.label}}
                        <span class="audit-num" v-if="v.num>0">{{v.num}}</span>
                    </div>
                </span>
                <div class="audit-main">
                    <div class="audit-search" v-if="activeName == 'create-audit'">
                        <el-input
                            class="search-box"
                            placeholder="搜索 (Enter发送)"
                            prefix-icon="el-icon-search"
                            v-model="key"
                            clearable
                            @clear="handleKey"
                            @keyup.enter.native="handleKey">
                        </el-input>
                        <el-button type="primary" @click="addAuditTask">
                            <div class="add-icon el-icon--left">
                                <div class="icon-line1"></div>
                                <div class="icon-line2"></div>
                            </div>
                            新建
                        </el-button>
                    </div>
                    <div :class="['audit-lis',{'create-audit-lis':activeName == 'create-audit'}]">
                        <div class="audit-item" v-for="(item,index) in v.lis" :key="'audit-item'+index" @click="showById(item.id)">
                            <i class="audit-tips" v-if="item.is_read == '0'"></i>
                            <div :class="['audit-status', getClassName(item.check_status)['classname']]" @click.stop="handleStatus(item)">
                                {{getClassName(item.check_status)['name']}}
                                <!-- 审核状态列表 -->
                                <div class="audit-status-lis" v-if="item.statusFlag">
                                    <div :class="['audit-lis-item',getClassName(sta.value)['classname']]" v-for="(sta,ista) in selectAuditStatus" :key="'audit-status'+ista" @click="changeStatus(item,sta)">{{sta.name}}</div>
                                </div>
                            </div>
                            <span :class="['user-task',userTaskType(item.sector_id).class]">{{userTaskType(item.sector_id).label}}</span>
                            <div class="audit-title">
                                {{item.title}}
                            </div>
                            <div class="audit-time">
                                {{item.ctime}}
                            </div>
                            <div class="task-detail">
                                <img class="portrait avator" :src="item.avatar" :alt="lastName(item.name)" v-if="item.name !== '' && item.avatar != defaultImg" />
                                <div class="tx avator" v-if="item.name !== '' && item.avatar == defaultImg">{{lastName(item.name)}}</div>
                                <div class="tj portrait avator" @click.stop="memberDisplay($event,i, index)" v-if="item.name === ''" />
                                <div class="ico-trash" @click.stop="delTask(item.id)" v-if="activeName == 'create-audit'"/>
                                <div class="mem" @click="$event.stopPropagation()">
                                    <member :x="memberX" :y="memberY" :clickBoxHeight="clickBoxHeight" :userType="item.sector_id == '0' ? 'all' : 'user'" :sector_id="item.sector_id" position_top="no-unset" v-if="item.showMember" @fromMember="fromMember" />
                                </div>
                            </div>
                        </div>
                        <el-empty v-if="v.lis.length == 0" description="暂无数据"></el-empty>
                    </div>
                </div>
                
                
            </el-tab-pane>
        </el-tabs>


        <add-task :show-all="isAddShow" name="audit" @changeShow="closeShow()" />

        <detail v-if="showDetail" @isShowDetail="isShowDetail" @traClose="traDaily($event)" :id="taskId" :tabName="activeName">
            <template slot="task-back">
                <div class="back-master-task" v-if="traId.length>1"  @click="isShowDetail('taskBack')">
                    <i class="el-icon-arrow-left"></i>
                    <span class="back-text">返回</span>
                </div>
            </template>
        </detail>
    </div>

</template>

<script>
import {
  postTaskChargeMod,
  taskCheckLis,
  checkTask,
  delCheckTask
} from '@/service/help';
import { lastName } from '@/utils/utils';
import addTask from '@/components/task/progress/addTask';
import Member from '@/components/task/progress/member';
import detail from '@/components/task/audit/detail/detail';
export default {
    name:'auditBox',
    data(){
        return{
            defaultImg:'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png',
            createAuditStatus:[
                {name:'等待审核',value:'0',checked:false,classname:'warning-audit'},
                {name:'审核通过',value:'1',checked:false,classname:'success-audit'},
                {name:'审核不通过',value:'-1',checked:false,classname:'error-audit'},
            ],
            preAuditStatus:[
                {name:'审核通过',value:'1',checked:false,classname:'success-audit'},
                {name:'审核不通过',value:'-1',checked:false,classname:'error-audit'},
            ],
            selectAuditStatus:[],
           activeName:'pre-audit',
           tab:[
            {label:'待我审核的任务',name:'pre-audit',num:9,lis:[]},
            {label:'我创建的审核任务',name:'create-audit',num:5,lis:[]}
           ],
           key:'',
           taskId:'',//任务id
            //    新建任务弹窗
           isAddShow: false, //新建
            // 详情
            showDetail:false,
            traId: [], //记录弹出数据   
        }
    },
    created(){
        this.selectAuditStatus = this.preAuditStatus
        this.getAuditLis()
    },
    components:{
        addTask,
        Member,
        detail
    },
    computed:{
        userTaskType(){
            return  function(id){
                if(id == '0'){
                    return {label:'通用',class:'normal-task'}
                }else{
                    return {label:'部门',class:'depart-task'}
                }
            }
        },
    },
    methods:{
        // 任务详情
         isShowDetail(e) {
            //正常关闭
            if (e === 'taskBack') {
                this.traId.pop(); //为了可以套娃打开
            } else if (e === 'task') {
                this.traId = []; //直接关闭
            }
            this.showDetail = false;
            if (this.traId.length > 0) {
                let id = this.traId.pop().toString();
                this.$nextTick(() => {
                    this.showById(id);
                });
            } else {
                console.log(777)
                this.getAuditLis();
            }
        },
        traDaily(id) {
            this.showDetail = false;
            this.$nextTick(() => {
                this.showById(id);
            });
        },
        showById(id) {
            this.traId.push(id); //打开详情页是记录id
            this.taskId = id;
            this.showDetail = true;
        },
        handleKey(val){
            this.getAuditLis()
        },
        changeStatus(item,sta){ // 改变审核任务状态
            console.log(item,sta,'改变审核状态')
            checkTask({
                id:item.id,
                check_status:sta.value
            }).then(res=>{
                if(res.data.success){
                    this.$message({
                        type: 'success',
                        message: '保存成功!',
                    });
                    this.getAuditLis()
                }
            })
        },
        delTask(id) {
            this.$confirm('确认要删除该这条任务吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'cancelButtonClass',
                confirmButtonClass: 'confirmButtonClass',
                showClose: true,
                type: 'warning',
                center: true,
            }).then(() => {
                var form = new FormData()
                form.append('id',id)
                delCheckTask(form).then(() => {
                    this.getAuditLis()
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                });
                })
                .catch(() => {
                });
            },
        memberDisplay(e, i,index) {
            const that = this
            this.resetShow();
            this.taskId = this.tab[i].lis[index].id;
            let dom = e.target;
            console.log(dom.getBoundingClientRect(),'dom.getBoundingClientRect()')
            this.memberX = dom.getBoundingClientRect().x;
            this.memberY = dom.getBoundingClientRect().y;
            this.clickBoxHeight = dom.getBoundingClientRect().height
            this.$nextTick(() => {
                that.tab[i].lis[index].showMember = !that.tab[i].lis[index].showMember;
            }); 
            this.$forceUpdate()
        },
        fromMember(name, id) {
            console.log(name);
            postTaskChargeMod({
                id: this.taskId,
                charge_id: id.toString(),
            }).then(() => {
                this.resetShow()
                this.getAuditLis();
            });
        },
        resetShow() {
            const that = this
            this.$nextTick(() => {
                that.tab.map(v=>{
                    if(v.lis.length>0){
                        v.lis.forEach(item => {
                            item.showMember = false;
                            item.statusFlag = false
                        });
                    }
                })
                
                that.$forceUpdate()
            });
            
        },
        getClassName(status){
            let obj = {}
            this.createAuditStatus.map(v=>{
                if(v.value == status){
                    obj = v
                }
            })
            return obj
        },
        handleStatus(item){ // 选择状态
            console.log(item)
            if(this.activeName == 'pre-audit' && item.check_status != '0') return false
            
            item.statusFlag = !item.statusFlag
        },
        closeShow(){ // 关闭新建任务
            this.isAddShow = false
            this.getAuditLis()
        },
        addAuditTask(){ // 新建审核任务
            this.isAddShow = true
        },
        getAuditLis(){
            taskCheckLis({
                key:this.key
            }).then(res=>{
                console.log(res)
                this.tab[0].lis = []
                this.tab[1].lis = []
                if(res.data.success){
                    this.tab[0].lis = res.data.data.check_by_me.tasklis.map(v=>{ 
                        v['statusFlag'] = false 
                        v['showMember'] = false 
                        return v
                    })
                    this.tab[0].num = res.data.data.check_by_me.uncheck
                    this.tab[1].lis = res.data.data.create_by_me.tasklis.map(v=>{ 
                        v['statusFlag'] = false  
                        v['showMember'] = false 
                        return v
                    })
                    this.tab[1].num = res.data.data.create_by_me.uncheck

                }
            })
        },
        handleClick(val){
            console.log(val,'val')
            console.log(this.activeName,'activeName')
            if(this.activeName == 'pre-audit'){
                this.selectAuditStatus = this.preAuditStatus
            }else{
                this.selectAuditStatus = this.createAuditStatus
            }
            
        },
        lastName(name) {
            return lastName(name);
        },
    }
}
</script>

<style lang="less">
@import '~@/assets/style/audit/auditbox.less';
</style>