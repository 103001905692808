export const NormalHeaderCol = [
    // {name:'排序',class:'task-index'},
    {name:'编号',class:'task-sn'},
    {name:'状态',class:'task-type'},
    {name:'类型',class:'task-class'},
    {name:'任务标题',class:'task-title'},
    
]
// 全部任务
export const AllHeaderCol = [
    {name:'平台',class:'task-item'},
    {name:'紧急程度',class:'task-item'},
    {name:'更新时间',class:'task-item'},
    {name:'创建时间',class:'task-item'},
    {name:'完成时间',class:'task-item'},
    {name:'',class:'task-item'},
]

export const OtherHeaderCol = [
    {name:'平台',class:'task-item'},
    {name:'紧急程度',class:'task-item'},
    {name:'更新时间',class:'task-item'},
    {name:'创建时间',class:'task-item'},
    {name:'完成时间',class:'task-item'}
]

// 易维护列表

export const EasyProjectCol = [
    {label:'泰科',value:'泰科'}
]

export const EasyMaintainCol = [
    {label:'项目名称',prop:'item_belong',width:'',align:'left',isSort:false},
    {label:'状态',prop:'status_text',width:'',align:'left',isSort:false},
    {label:'备忘录',prop:'note',width:'',align:'left',isSort:false},
    {label:'最新进度',prop:'ec_content',width:'',align:'left',isSort:false},
    {label:'下次维护时间',prop:'next',width:'220',align:'left',isSort:true},
    {label:'最近维护时间/维护人',prop:'lately',width:'230',align:'left',isSort:true},
    {label:'维护类型',prop:'em_type',width:'',align:'left',isSort:false},
    // {label:'操作',prop:'sn',width:'',align:'center',isSort:false,type:'btns',btns:['edit','del']},
]

export const EasyRemindCol = [
    {label:'内容',prop:'message',width:'',align:'center',isSort:false},
    {label:'提醒时间',prop:'re_time',width:'',align:'center',isSort:false},
    {label:'提醒人',prop:'re_uname',width:'',align:'center',isSort:false},
    {label:'操作',prop:'status_text',width:'',align:'center',isSort:false},
]

export const EasyMainRecordCol = [
    {label:'状态',prop:'status_text',width:'',align:'center',isSort:false},
    {label:'类型',prop:'type',width:'',align:'center',isSort:false},
    {label:'图片',prop:'picture_url',width:'',align:'center',isSort:false,type:'showPictrue'},
    {label:'内容',prop:'remarks',width:'',align:'center',isSort:false}, 
    {label:'维保人',prop:'app_uname',width:'',align:'center',isSort:false},
    {label:'时间',prop:'up_time',width:'',align:'center',isSort:false},
    {label:'操作',prop:'app_token',width:'',align:'center',isSort:false,type:'copyToken'},
]

// 文件列表
export const EasyMainFileCol = [
    {label:'操作',prop:'sn',width:'',align:'center',isSort:false,type:'btns',btns:['edit','del']},
    {label:'名称',prop:'filename',width:'',align:'center',isSort:false},
    {label:'文件',prop:'picture',width:'',align:'center',isSort:false,type:'files'},
    {label:'上传时间',prop:'ctime',width:'',align:'center',isSort:false},
    {label:'上传人',prop:'uname',width:'',align:'center',isSort:false},
    {label:'备注',prop:'remarks',width:'',align:'center',isSort:false},
]
// 维修列表
export const EasyMainUpdateCol = [
    {label:'操作',prop:'sn',width:'',align:'center',isSort:false,type:'btns',btns:['edit','del']},
    {label:'名称',prop:'title',width:'',align:'center',isSort:false},
    {label:'图片',prop:'picture',width:'',align:'center',isSort:false,type:'files'},
    {label:'上传时间',prop:'up_time',width:'',align:'center',isSort:false},
    {label:'上传人',prop:'up_name',width:'',align:'center',isSort:false},
    {label:'维修时间',prop:'date',width:'',align:'center',isSort:false},
    {label:'内容',prop:'content',width:'',align:'center',isSort:false},
    {label:'类型',prop:'status_text',width:'',align:'center',isSort:false},
    {label:'关联硬件',prop:'hardware',width:'',align:'center',isSort:false},
]

export const EmableEasyMainUpdateCol = [ // 内嵌的表格
    {label:'名称',prop:'title',width:'',align:'center',isSort:false},
    {label:'图片',prop:'picture',width:'',align:'center',isSort:false,type:'files'},
    {label:'上传时间',prop:'up_time',width:'',align:'center',isSort:false},
    {label:'上传人',prop:'up_name',width:'',align:'center',isSort:false},
    {label:'内容',prop:'content',width:'',align:'center',isSort:false},
    {label:'类型',prop:'type',width:'',align:'center',isSort:false},
]
// 硬件列表
export const EasyMainHardwareCol = [
    {label:'操作',prop:'sn',width:'',align:'center',isSort:false,type:'btns',btns:['edit','del']},
    {label:'名称',prop:'hardware',width:'',align:'center',isSort:false},
    {label:'图片',prop:'picture',width:'',align:'center',isSort:false,type:'files'},
    {label:'购买时间',prop:'buy_time',width:'',align:'center',isSort:false},
    {label:'年限',prop:'uselimit',width:'',align:'center',isSort:false},
    {label:'维修记录',prop:'em_records',width:'',align:'center',isSort:false,type:'embedded-table',col:EmableEasyMainUpdateCol},
    {label:'状态',prop:'status_text',width:'',align:'center',isSort:false},
    {label:'备注',prop:'remarks',width:'',align:'center',isSort:false},
]



export const HardwareTypeOptions = [
    {label:'更换',value:'更换'},
    {label:'维修',value:'维修'},
    {label:'报废',value:'报废'},
]

export const HardwareStatusOptions = [
    {label:'使用中',value:'使用中'},
    {label:'备用',value:'备用'},
    {label:'已报废',value:'已报废'},
    {label:'已超期',value:'已超期'},
]