<template>
    <div class="task-index">
        <el-input-number v-model="num_index" controls-position="right" @change="handleChange" :min="1" ></el-input-number>
        <el-button type="primary" @click.native="handleSave">保存</el-button>
    </div>


</template>

<script>
export default {
    props:['num'],
    data(){
        return{
            num_index:1
        }
    },
    created(){
        console.log(this.num,'=====num====')
       this.num_index =  Number(this.num)
    },
    watch:{
        num(newVal){
            this.num_index = newVal
        }
    },
    methods:{
        handleChange(value) {
            console.log(value);
            this.num_index = value
            this.handleSave()
        },
        handleSave(){
            this.$emit('handleSaveIndex', this.num_index)
        }
    }

}
</script>

<style lang="less" scoped>
.task-index{
    text-align: center;
}
</style>