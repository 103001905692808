<template>
  <div class="popup detail" @click="ctrlShow('cover')">
    <div class="frame" @click="$event.stopPropagation(), (showStatue = false), (showCharge = false), (modName = false), (taskVisible = false)">
      <header>
        <div class="header-top">
          <div class="header-top-left" >
            <slot name="task-back" v-if="summary.is_subtask === '1'"></slot>
            <div class="task-head-title" v-if="summary.is_subtask === '1'">| 任务</div>
          </div>
          <div class="header-top-right">
              <span :class="['user-task',userTaskType(summary.sector_id).class]">{{userTaskType(summary.sector_id).label}}</span>
              <el-popover
                popper-class="change-task"
                placement="bottom"
                width="300"
                trigger="manual"
                v-model="taskVisible">
                <div v-if="summary.sector_id != '0'" class="change-task-main">
                  <p>是否将该任务转为通用任务？</p>
                </div>
                <div v-else class="change-task-main">
                  <p>该任务转为部门任务，请先选择部门</p>
                  <el-select v-model="task_sector" clearable  placeholder="部门" class="select" >
                    <el-option v-for="(item, index) in userInfo.snames" :key="index" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
                <div  class="dialog-footer">
                  <el-button size="mini" @click="taskVisible = false">取 消</el-button>
                  <el-button size="mini" type="primary" @click="handleChangeTask">确 定</el-button>
                </div>
                <el-button slot="reference" @click.stop="taskVisible = true" class="task-change-btn">任务转换</el-button> 
              </el-popover>
              
              <!-- <el-button class="file-button" v-if="summary.status !== 3" @click.stop="inFile" :style="'width:80px'">归档</el-button> -->
              <span class="ico-trash ico-span" @click.stop="delTask(id)" />
              <span class="ico-line ico-span" />
              <span class="close-ico ico-span" @click="ctrlShow('close')"></span>
          </div>
        </div>
        <div class="title">
          
          
          <span class="title-name" v-if="summary.is_subtask === '1'" v-show="!modName" @click.stop="taskName()"
            >{{ $store.state.kv['dadTitle'] }}—{{ taskNameInput }}</span
          >
          <span class="title-name" v-else v-show="!modName" @click.stop="taskName()">{{ taskNameInput }}</span>
          <textarea
            @click.stop
            @keyup.enter="modTaskName"
            @focus="tnFocus"
            @input="autoTextarea"
            class="taskName"
            v-if="modName"
            v-model="newTaskName"
            ref="taskName"
          />
          
        </div>
      </header>
      <main>
        <div class="summary">
          <ul class="summary-ul">
            <li class="li1" @click.stop="handleAuditStatus" v-if="summary.is_check === '1'">
              <img :src="getAuditStatus(summary.check_status).ico" alt="" />
              <div v-show="showStatue" class="select">
                <ul>
                  <li
                    v-for="(item, index) in xIcoList"
                    :key="index"
                    @click="changAuditStatus(item.value)"
                    :class="{ atStatus: item.value === summary.check_status }"
                  >
                    <img :src="item.minIco" /><span>{{ item.str }}</span>
                  </li>
                </ul>
              </div>
              <div class="span">
                <span :style="{ color: getAuditStatus(summary.check_status).color }" class="span-line">{{
                  getAuditStatus(summary.check_status).str
                }}</span
                ><span class="span-line2">当前状态</span>
              </div>
            </li>
            <li class="li1" @click.stop="showStatue = !showStatue" v-else>
              <img :src="normalXico[summary.status].ico" alt="" />
              <div v-show="showStatue" class="select">
                <ul>
                  <li
                    v-for="(item, index) in normalXicoList"
                    :key="index"
                    @click="changStatus(index)"
                    :class="{ atStatus: index === summary.status }"
                  >
                    <img :src="item.ico" /><span>{{ item.str }}</span>
                  </li>
                </ul>
              </div>
              <div class="span">
                <span :style="{ color: normalXico[summary.status].color }" class="span-line">{{
                  normalXico[summary.status].str
                }}</span
                ><span class="span-line2">当前状态</span>
              </div>
            </li>
            <li class="li2" @click.stop="showCharge = !showCharge">
              <div class="head-draw img" v-if="summary.charge_name !== ''">
                {{ lastName(summary.charge_name) }}
              </div>
              <div class="fzr" v-else />
              <div @click="$event.stopPropagation()" v-if="showCharge" class="showCharge">
                <member :userType="userType" :sector_id="summary.sector_id" @fromMember="fromMember"/>
              </div>
              <div class="span">
                <span class="span-line">{{ summary.charge_name }}</span
                ><br v-if="summary.charge_name !== ''" /><span
                  class="span-line2"
                  :class="{ vertical: summary.charge_name === '' }"
                  >负责人</span
                >
              </div>
            </li>
            <!-- <li class="li3" @click="$refs.stime.focus()">
              <div class="start-time" />
              <div class="bl1">
                <el-date-picker
                  style="opacity: 0"
                  ref="stime"
                  v-model="stime"
                  type="date"
                  placeholder="选择开始时间"
                  id="st"
                  :picker-options="stimeOptions"
                >
                </el-date-picker>
              </div>
              <div class="span">
                <span class="span-line">{{ stime1 }}</span
                ><span class="span-line2" :class="{ vertical: stime1 === '' }">开始时间</span>
              </div>
            </li>
            <li class="li4" @click="$refs.etime.focus()">
              <div class="end-time" />
              <div class="bl1">
                <el-date-picker
                  style="opacity: 0"
                  ref="etime"
                  v-model="etime"
                  type="date"
                  placeholder="选择结束时间"
                  id="et"
                  :picker-options="etimeOptions"
                >
                </el-date-picker>
              </div>
              <div class="span">
                <span class="span-line">{{ etime1 }}</span
                ><span class="span-line2" :class="{ vertical: etime1 === '' }">结束时间</span>
              </div>
            </li> -->
          </ul>
        </div>
        <nav>
          <ul>
            <li
              v-for="item in detailNav"
              :key="item.key"
              @click="handleClick(item)"
              @mouseleave="mouseleave($event, item)"
              @mouseenter="mouseenter($event, item)"
            >
              <div :class="{ dative: navKey === item.key }">
                <img :src="item.activeIco" alt="yes" v-if="navKey === item.key" width="18px" />
                <img :src="item.ico" alt="" v-else width="18px" />
                <span>{{ item.str }}</span>
                <span v-if="item.key !== 'information'">({{ item.sum }})</span>
              </div>
            </li>
          </ul>
        </nav>
        <keep-alive>
          <component
            :is="navKey"
            :name="summary.charge_name"
            :id="id"
            :cont="summary.content"
            :level="summary.level"
            :join_id="summary.join_id"
            :taskTitle="summary.title"
            :userType="userType"
            :sector_id="summary.sector_id"
            @traClose="traClose($event)"
            @ordinary="ordinary($event)"
          >
          </component>
        </keep-alive>
        <operate :id="this.id" :reset="reset" :record="record"></operate>
      </main>
      <footer>
        <div class="edit-comment">
          <div class="head-draw head">{{ lastName($store.state.account) }}</div>
          <input
            class="comment-input"
            type="text"
            placeholder="评论内容，文字上限500（Ctrl+Enter发送）"
            @keydown="sent"
            v-model="comment"
          />
          <button class="ok" @click="sentByClick">发送</button>
        </div>
      </footer>
    </div>
    <plane v-if="$store.state.showPlane"></plane>

  </div>
</template>

<script>
import information from '@/components/task/progress/detail/compoment/information';
import subtag from '@/components/task/progress/detail/compoment/subtag';
import estimate from '@/components/task/progress/detail/compoment/estimate';
import enclosure from '@/components/task/progress/detail/compoment/enclosure';
import operate from '@/components/task/progress/detail/compoment/operate';
import plane from '@/components/task/progress/detail/compoment/plane';
import {
  postTaskChargeMod,
  postTaskCommentNew,
  postTaskDel,
  postTaskSubDel,
  postTaskEtimeMod,
  postTaskInfoLis,
  postTaskStatusMod,
  postTaskStimeMod,
  postTaskTitleMod,
  taskChange,
  checkTask
} from '@/service/help';
import member from '@/components/task/progress/member';
import { dateFormat } from '@/utils/utils';
import { xIco } from '@/common/js/index'
export default {
  name: 'detail',
  data() {
    return {
      modName: false,
      taskNameInput: '',
      newTaskName: '',
      titleIco: require('../../../../assets/image/icon-progress/icon_bj@2x.png'),
      activeTitle: require('../../../../assets/image/icon-progress/icon_bj@2x(1).png'),
      summary: {
        charge_id: '',
        charge_name: '',
        content: '',
        ctime: '',
        dadtask_id: '',
        etime: '',
        id: '',
        is_subtask: '0',
        SubSum: 0,
        UploadSum: 0,
        WorktimeSum: 0,
        join_id: [
          /*          {
            avatar: '',
            id: '',
            name: '',
          },*/
        ],
        level: '',
        sn: '',
        status: 0,
        stime: '',
        title: '',
        type: '',
      },
      showStatue: false,
      showCharge: false,
      normalXico:xIco,
      xIco: [
        {
          ico: require('../../../../assets/image/icon-progress/icon_jxz@2x.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_jxz_16@2x.png'),
          str: '等待审核',
          color: '#FFC108',
          bgColor: 'rgba(255,193,8,0.1)',
          value:'0'
        },
        {
          ico: require('../../../../assets/image/icon-progress/icon_ywc@2x.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_ywc_16@2x.png'),
          str: '审核通过',
          color: '#1EE48E',
          bgColor: 'rgba(30,228,142,0.1)',
          value:'1'
        },
        {
          ico: require('../../../../assets/image/icon-progress/icon_wks@2x.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_wks_16@2x.png'),
          str: '审核不通过',
          color: '#FF6057',
          bgColor: 'rgba(255,96,87,0.1)',
          value:'-1'
        }
      ],
      detailNav: [
        {
          key: 'information',
          ico: require('../../../../assets/image/icon-progress/icon_rwxi@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_rwxi_h@2x.png'),
          str: '任务信息',
          sum: 0,
        },
        {
          key: 'subtag',
          ico: require('../../../../assets/image/icon-progress/icon_zrw@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_zrw_h@2x.png'),
          str: '任务详情',
          sum: 0,
        },

        {
          key: 'estimate',
          ico: require('../../../../assets/image/icon-progress/icon_ygsj@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_ygsj_h@2x.png'),
          str: '预估完成时间',
          sum: 0,
        },
        {
          key: 'enclosure',
          ico: require('../../../../assets/image/icon-progress/icon_fj@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_fj_h@2x.png'),
          str: '附件',
          sum: 0,
        },
      ],
      navKey: '',
      status: [9, 1, 1],
      comment: '',
      reset: false, //被监听发评论
      record: false, //被监听刷新轨迹
      stime: '',
      etime: '',
      stime1: '',
      etime1: '',
      stimeOptions: {
        disabledDate: time => {
          if (this.etime !== '') {
            return time.getTime() > this.etime;
          }
        },
      },
      etimeOptions: {
        disabledDate: time => {
          if (this.stime !== '') {
            return time.getTime() < this.stime;
          }
        },
      },
      // 区分通用任务和不同任务，所请求的人员列表
      userType:'all', // 将要获取的人员列表类型 all 全部人 user 部门下的人
      // 通用和部门任务的转换
      taskVisible:false,
      task_sector:''
    };
  },
  props:['id','tabName'],
  components: {
    information,
    subtag,
    estimate,
    enclosure,
    operate,
    member,
    plane,
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    xIcoList() {
      if (this.summary.check_status === '0') { // 待审核时 
        return this.xIco.slice(1, 3);
      } else {
        return this.xIco
      }
    },
    normalXicoList(){
      if (this.summary.status === 2) {
        return this.normalXico;
      } else {
        return this.normalXico.slice(0, 3);
      }
      
    },
    userTaskType(){
      return  function(id){
        if(id == '0'){
          return {label:'通用',class:'normal-task'}
        }else{
          return {label:'部门',class:'depart-task'}
        }

      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
  },
  methods: {
    // 点击审核按钮
    handleAuditStatus(){
      if(this.summary.check_status != '0' && this.tabName == 'pre-audit')  return
      this.showStatue = !this.showStatue
    },
    // 获取审核任务的
    getAuditStatus(status){
      let obj = {}
      this.xIco.map(v=>{
          if(v.value == status){
              obj = v
          }
      })
      return obj
    },
    // 任务转换
    handleChangeTask(){
      let data = {id:this.id}
      if(this.summary.sector_id == '0'){ // 当前是通用任务时
        if(this.task_sector == ''){
          this.$message({
            type: 'warning',
            message: '部门不能为空!',
          });
          return
        }
        data.sector_id = this.userInfo.section_ids[this.userInfo.snames.findIndex(v=> v == this.task_sector)]
      }else{
        data.sector_id = '0'
      }
      taskChange(data).then(res=>{
        if(res.data.success){
          this.$message({
            type: 'success',
            message: '转换成功!',
          });
          this.taskVisible = false
          this.getData()
        }
        
      })
    },
    getData() {
      postTaskInfoLis({ id: this.id.toString() }).then(res => {
        let data = res.data.data;
        this.$store.commit('set', { type: 'taskInfo', data });
        this.summary = data;
        this.taskNameInput = this.summary.title;
        this.stime1 = this.parseData(this.summary.stime);
        this.etime1 = this.parseData(this.summary.etime);
        this.userType = data.sector_id == '0' ? 'all' : 'user'
        console.log(data, data.join_id);
        if (this.stime1 !== '') {
          this.stime = new Date(this.summary.stime); //初始化日期选择器数值
        }
        if (this.etime1 !== '') {
          this.etime = new Date(this.summary.etime);
        }
        this.detailNav[1].sum = this.summary.SubSum;
        this.detailNav[2].sum = this.summary.WorktimeSum;
        this.detailNav[3].sum = this.summary.UploadSum;
        if (data.is_subtask === '0') {
          //需要保存父任务的标题
          this.$store.commit('setkv', { k: 'dadTitle', v: data.title });
          this.$store.commit('setkv', { k: 'dadId', v: data.id });
        } else {
          //屏蔽子任务
          this.detailNav.splice(0, 1);
        }
        this.handleClick(this.detailNav[0]);
        this.record = !this.record; //刷新轨迹
      });
    },
    delTask(id) {
      this.$confirm('确认要删除该这条任务吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          if (this.summary.is_subtask === '0') {
            postTaskDel({ id: id.toString() }).then(() => {
              this.$emit('isShowDetail', 'task');
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            });
          } else if (this.summary.is_subtask === '1') {
            postTaskSubDel({ id: id.toString(), dadtask_id: this.$store.state.kv['dadId'] }).then(() => {
              this.$emit('isShowDetail', 'taskBack');
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    ctrlShow(fromDom = 'cover') {
      if (fromDom === 'cover') {
        this.$emit('isShowDetail', 'taskBack');
      } else if (fromDom === 'close') {
        this.$emit('isShowDetail', 'task');
      }
    },
    traClose(id) {
      this.$emit('traClose', id, 'task');
    },
    ordinary(name) {
      //子组件通知父组件通常入口
      this.record = !this.record; //改变操作轨迹
      switch (name) {
        case 'addSub': {
          this.detailNav.forEach(item => {
            if (item.key === 'subtag') {
              item.sum++;
            }
          });
          break;
        }
        case 'delSub': {
          this.detailNav.forEach(item => {
            if (item.key === 'subtag') {
              item.sum--;
            }
          });
          break;
        }
        case 'addTime': {
          this.detailNav.forEach(item => {
            if (item.key === 'estimate') {
              item.sum++;
            }
          });
          break;
        }
        case 'delTime': {
          this.detailNav.forEach(item => {
            if (item.key === 'estimate') {
              item.sum--;
            }
          });
          break;
        }
        case 'addSource': {
          this.detailNav.forEach(item => {
            if (item.key === 'enclosure') {
              item.sum++;
            }
          });
          break;
        }
        case 'delSource': {
          this.detailNav.forEach(item => {
            if (item.key === 'enclosure') {
              item.sum--;
            }
          });
          break;
        }
        default:
          break;
      }
    },
    handleClick(item) {
      this.navKey = item.key;
    },
    mouseenter(event, ico) {
      let target = event.target.firstChild.firstChild;
      target.setAttribute('src', ico.activeIco);
    },
    mouseleave(event, ico) {
      let target = event.target.firstChild.firstChild;
      if (target.getAttribute('alt') === 'yes') target.setAttribute('src', ico.activeIco);
      else target.setAttribute('src', ico.ico);
    },
    changAuditStatus(status) {
      //修改当前审核状态
      checkTask({
        check_status: status,
        id: this.id.toString(),
      }).then(() => {
        this.record = !this.record; //刷新轨迹
        this.summary.check_status = status;
        this.showStatue = false;
      });
    },
    changStatus(index) {
      //修改当前状态
      postTaskStatusMod({
        status: index.toString(),
        id: this.id.toString(),
      }).then(() => {
        this.record = !this.record; //刷新轨迹
        this.summary.status = index;
        this.showStatue = false;
      });
    },
    sent(event) {
      let oEvent = event;
      if (oEvent.ctrlKey && oEvent.keyCode === 13) {
        this.sentByClick();
      }
    },
    sentByClick() {
      if (this.comment === '') {
        this.$alert('不能发送空评论', '当前输入内容为空', { confirmButtonText: '确定', center: true });
      } else {
        postTaskCommentNew({
          task_id: this.id.toString(),
          content: this.comment,
        }).then(() => {
          this.reset = !this.reset;
          this.comment = '';
        });
      }
    },
    parseData(time) {
      if (time === '') return '';
      else return time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
    taskName() {
      this.modName = !this.modName;
      if (this.modName) {
        this.newTaskName = this.taskNameInput;
        this.$nextTick(() => {
          this.$refs.taskName.focus();
        });
      }
    },
    tnFocus(e) {
      let scrollHeight = e.target.scrollHeight;
      e.target.style.height = scrollHeight + 2 + 'px';
    },
    autoTextarea(e) {
      let scrollHeight = e.target.scrollHeight;
      e.target.style.height = scrollHeight + 2 + 'px';
    },
    fromMember(name, id) {
      this.showCharge = false;
      this.summary.charge_name = name;
      this.summary.charge_id = id;
      postTaskChargeMod({
        id: this.id.toString(),
        charge_id: this.summary.charge_id,
      }).then(() => {
        this.record = !this.record; //刷新轨迹
      });
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    modTaskName() {
      this.newTaskName = this.newTaskName.replace('\n', '');
      postTaskTitleMod({
        title: this.newTaskName,
        id: this.id.toString(),
      }).then(() => {
        this.taskNameInput = this.newTaskName;
      });
      this.modName = false;
    },
    inFile() {
      //归档方法
      if (this.summary.status !== 2) {
        this.$alert('该任务不是已完成的任务', '归档错误', { confirmButtonText: '确定', center: true });
      } else {
        this.changStatus(3);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/detail.less';
</style>
